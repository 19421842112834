var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap js-car-reco" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "back-btn",
            attrs: {
              icon: "el-icon-arrow-left",
              size: "small",
              type: "primary",
              plain: "",
            },
            on: { click: _vm.$goBack },
          }),
          _c("label", { staticClass: "label" }, [_vm._v("车辆详情")]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "js-common-content" }, [
      _c("ul", { staticClass: "info-list" }, [
        _c("li", [
          _c("label", [_vm._v("车牌号码：")]),
          _c("span", { staticClass: "carnum" }, [
            _vm._v(_vm._s(_vm.carInfo.licensePlate)),
          ]),
        ]),
        _c("li", [
          _c("label", [_vm._v("车主姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.carOwner))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("车主身份：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.idcard))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("车辆型号：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.carModel))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("车辆颜色：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.carColor))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("警示：")]),
          _vm.carInfo.status == 0 ? _c("span", [_vm._v("正常")]) : _vm._e(),
          _vm.carInfo.status == 1 ? _c("span", [_vm._v("警示")]) : _vm._e(),
        ]),
        _c("li", [
          _c("label", [_vm._v("创建时间：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.gmtCreated))]),
        ]),
        _c("li", [
          _c("label", [_vm._v("上次修改时间：")]),
          _c("span", [_vm._v(_vm._s(_vm.carInfo.gmtUpdated))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }