<template>
  <div class="js-common-wrap js-car-reco">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">车辆详情</label>
      </div>
    </div>
    <div class="js-common-content">
      <ul class="info-list">
        <li><label>车牌号码：</label><span class="carnum">{{ carInfo.licensePlate }}</span></li>
        <li><label>车主姓名：</label><span>{{ carInfo.carOwner }}</span></li>
        <li><label>车主身份：</label><span>{{ carInfo.idcard }}</span></li>
        <li><label>车辆型号：</label><span>{{ carInfo.carModel }}</span></li>
        <li><label>车辆颜色：</label><span>{{ carInfo.carColor }}</span></li>
        <li><label>警示：</label>
          <span v-if="carInfo.status == 0">正常</span>
          <span v-if="carInfo.status == 1">警示</span>
        </li>
        <li><label>创建时间：</label><span>{{ carInfo.gmtCreated }}</span></li>
        <li><label>上次修改时间：</label><span>{{ carInfo.gmtUpdated }}</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      currentID: '',
      carInfo:{}
    }
  },
  mounted() {
    this.currentID = this.$route.query.id
    this.getDetails()
  },
  methods: {
    getDetails(){
      this.$axios({
        method: 'get',
        url: `api/csp/lpr/v1/lpr/${this.currentID}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.carInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.carnum{
  width: 150px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #1d85fc;
  border-radius: 2px;
  text-align: center;
  color: #fff;
}
.info-list{
  padding: 30px 60px;
  height: 100%;
  background: #fff;
  font-size: 14px;
  li{
    margin-bottom: 10px;
    line-height: 32px;
  }
  label{
    width: 150px;
    display: inline-block;
  }
}
</style>
